import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import PropTypes from 'prop-types';
import './report.scss';

import React, { useEffect } from 'react';

import { FormControl, FormControlLabel, Switch } from '@mui/material';
import { useParams } from 'react-router-dom';
import PassaggioDiConsegne from '../../../components/carrefour/passaggioDiConsegne';
import ReportControlli from '../../../components/carrefour/reportControlli';
import ReportRm from '../../../components/carrefour/reportRM';
import { useLocalStorage } from '../../../hooks/useLocalStorage';
import AgentsOperatorView from '../../../components/agents/agentsOperatorView';
import CarrefourCredentials from './Credentials';
// import CarrefourStats from './Stats';
// import CarrefourTasks from './Tasks';


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                    {/* <Typography></Typography> */}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};
const CarrefourReport = () => {
    const [tab, setTab] = useLocalStorage('carrefour-report-tab',0);
    const [displayFuture,setDisplayFuture] = React.useState(false);
    const [displayedScheds,setDisplayedScheds] = React.useState([]);
    const handleTabChange = (event, newValue) => {
        setTab(newValue);
    };
    const params = useParams();
    const what = params.what;
    
    

    useEffect(()=>{
        const tabs = ["rm","controlli","pdc","agents","stats","history","creds"];
        const candidateTab = tabs.indexOf(what);
        if (candidateTab > -1) setTab(candidateTab);
    },[what]);
   
    

    return (
        <div>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={tab} onChange={handleTabChange}>
                    <Tab label="RIORDINO MERCATI" id="RIORDINO MERCATI" />
                    <Tab label="CONTROLLI" id="CONTROLLI" />
                    <Tab label="PASSAGGIO DI CONSEGNE" id="PASSAGGIO DI CONSEGNE" />
                    <Tab label="AGENTI" id="AGENTI" />
                    <Tab label="CREDENZIALI" id="CREDENZIALI" />
                    {/* <Tab label="STATISTICHE" id="STATISTICHE" /> */}
                    {/* <Tab label="STORICO ATTIVITA'" id="STORICO ATTIVITA'" /> */}
                </Tabs>
            </Box>
           
            <TabPanel p={4} value={tab} index={0}> {/* Riordino mercati */}
                <Box>
                    <ReportRm />
                </Box>

            </TabPanel>
            <TabPanel p={4} value={tab} index={1}> {/* Controlli */}
                <Box>
                    {displayFuture}
                    <FormControl>
                        <FormControlLabel control={<Switch color="background" value={displayFuture} onChange={e=>setDisplayFuture(e.target.checked)}></Switch>} label="Mostra schedulazioni future"></FormControlLabel>
                    </FormControl>
                </Box>
                <Box>
                    <ReportControlli displayFuture={displayFuture}></ReportControlli>
                </Box>
                <Box>
                    <Grid container spacing={4}>
                        {displayedScheds ? (
                            displayedScheds.filter(s=>(displayFuture || s.at <= new Date())).map(x=>({...x,isFuture: x.at > new Date()})).map((s,sI)=>(
                                <React.Fragment key={sI}>
                                    {
                                        s.comp ? (
                                            <s.comp {...s}></s.comp>
                                        ) : (JSON.stringify(s,null,2))
                                    }
                                </React.Fragment>
                            ))
                        ) : (<></>)}
                        
                    </Grid>
                </Box>
            </TabPanel>
            <TabPanel p={4} value={tab} index={2}>
                <PassaggioDiConsegne></PassaggioDiConsegne>
            </TabPanel>
            <TabPanel p={4} value={tab} index={3}>
                <AgentsOperatorView poolName="RPA_SALAMACCHINE_CARREFOUR_ALL_BOTS_1" svcName="agentsInSalaMacchine"/>
            </TabPanel>
            <TabPanel p={4} value={tab} index={4}>
                <CarrefourCredentials />
            </TabPanel>
            {/* <TabPanel p={4} value={tab} index={5}>
                <CarrefourStats />
            </TabPanel> */}
            {/* <TabPanel p={4} value={tab} index={6}>
                <CarrefourTasks />
            </TabPanel> */}
            





        </div >
    )
}

export default CarrefourReport